<template>
  <div class="recruitment">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>岗位招聘</h1>
          <p><a href="/">首页</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />公司
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />岗位招聘
          </p>
        </div>
      </div>
      <div class="auto-container">
        <div class="info">
          <Row :gutter="30">
            <Col
              :xl="12"
              :lg="12"
              :md="24"
              :sm="24"
            >
            <div class="card">
              <p class="jobName">.Net开发工程师</p>
              <p>职位描述:</p>
              <p class="jobInfo">1、负责基于.Net Core的后端开发；<br>
                2、熟悉微信小程序开发及智能硬件对接；<br>
                3、可能会有部分基于传统.Net控件的后端开发；<br>
                4、编写开发过程中的各类技术文档；<br>
                5、解决开发工程中的主要问题和攻克各种技术难题；</p>
              <p style="margin-top:10px">任职要求：</p>
              <p class="jobInfo">1、本科以上学历，1年以上开发经验（不考虑未取得学历的实习生）； <br>
                2、熟练掌握Asp.Net及.Net Core相关技术经验加分；<br>
                3、熟练常用数据库，熟练使用数据库脚本，熟悉mysql数据库经验加分；<br>
                4、熟悉Socket编程，有开发Socket经验加分； <br>
                5、熟悉docker容器使用经验加分；<br>
                6、了解前端HTML、CSS、及部分框架（如 Bootsrtap、Angularjs）经验加分； <br>
                7、熟练掌握vue.js前端开发经验加分；</p>
            </div>
            </Col>
            <Col
              :xl="12"
              :lg="12"
              :md="24"
              :sm="24"
            >
            <div class="card">
              <p class="jobName">软件运维工程师</p>
              <p>职位描述:</p>
              <p class="jobInfo">1、负责公司软件使用的售后服务、技术支持；<br>
                2、负责公司软件的用户培训及支持；<br>
                3、负责公司新开发软件及产品的测试工作；</p>
              <p style="margin-top:10px">任职要求：</p>
              <p class="jobInfo">1、有一定硬件和网络及服务器维护经验；<br>
                2、有软件政府采购经验者加分；<br>
                3、有一定文笔经验、标书编写者加分；</p>
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import recruitment from "./recruitment.js";
export default recruitment;
</script>

<style lang="less" scoped>
@import "./recruitment.less";
</style>